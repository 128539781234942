import L from "leaflet";

export default {
    "vehicles.get": (response, context) => {
        return response;
    },
    "segment.post": (response, context) => {
        return response;
    },
    "plan.post": (response, context) => {
        return response;
    },
    "plan-execute.post": (response, context) => {
        return response;
    },
    "isalive.get": (response, context) => {
        return response;
    },
    "pots.get": (response, context) => {
        return response;
    },
    "stations.get": (response, context) => {
        return response;
    },
    "geocoder.get": (response, context) => {
        return response;
    },
    "reversegeocoder.get": (response, context) => {
        return response;
    },
    "plans-download.post": (response, context) => {
        // Check response status
        if (response.data.status !== "OK") {
            throw new Error(
                `Request failed with status: ${response.status}: ${response.data.status}`
            );
        }

        // Initialize OSM usability flag
        let osmUsable = false;

        // Map planned routes to match version 1.0 output structure
        response.data.plans = response.data.plannedRoutes.map((route) => {
            const hardCopy = JSON.parse(JSON.stringify(route));

            // Create extra data object
            const extra = {
                allLatLongs: [],
                allLatLongsExclWalk: [],
                warnings: [],
                signature: "",
            };
            let totalPriceInCzk = 0.0;

            // Generate signature
            if (route.segments.length > 0) {
                extra.signature =
                    `${route.segments[0].start.geoCoordinate.lon},${route.segments[0].start.geoCoordinate.lat}:` +
                    `${
                        route.segments[route.segments.length - 1].end
                            .geoCoordinate.lon
                    },${
                        route.segments[route.segments.length - 1].end
                            .geoCoordinate.lat
                    }:` +
                    `${route.segments.length}:${route.stats.distanceMeters}:${route.stats.durationSeconds}`;
            }

            // Process segments
            route.segments.forEach((segment) => {
                // Check for OSM links usability
                if (
                    !osmUsable &&
                    segment.additionalData &&
                    segment.additionalData.osmLinks &&
                    segment.additionalData.osmLinks.length > 0
                ) {
                    osmUsable = true;
                }

                // Add lat/longs to extras
                extra.allLatLongs.push(
                    L.latLng(
                        segment.start.geoCoordinate.lat,
                        segment.start.geoCoordinate.lon
                    ),
                    L.latLng(
                        segment.end.geoCoordinate.lat,
                        segment.end.geoCoordinate.lon
                    )
                );

                if (segment.transportMode !== "WALK") {
                    extra.allLatLongsExclWalk.push(
                        L.latLng(
                            segment.start.geoCoordinate.lat,
                            segment.start.geoCoordinate.lon
                        ),
                        L.latLng(
                            segment.end.geoCoordinate.lat,
                            segment.end.geoCoordinate.lon
                        )
                    );
                }

                if (
                    segment.stats.fare &&
                    segment.stats.fare.price &&
                    segment.stats.fare.currencyCode === "CZK"
                ) {
                    totalPriceInCzk += segment.stats.fare.price;
                } // end if

                segment.rideDetails = {};

                if ("PT" == segment.transportMode) {
                    segment.rideDetails.ptDetails = segment.details;
                    segment.rideDetails.ptDetails.stopTimes = segment.details.viaStopTimes;
                    segment.rideDetails.ptDetails.route.ptVehicle = segment.rideDetails.ptDetails.route.vehicleType;
                    segment.rideDetails.ptDetails.route.routeShortName = segment.rideDetails.ptDetails.route.shortName;
                    segment.rideDetails.ptDetails.route.routeId = segment.rideDetails.ptDetails.route.id;

                }
            });

            // Map segments to legs
            const legs = route.segments.map((segment) => ({
                start: {
                    geoLocation: {
                        lat: segment.start.geoCoordinate.lat,
                        lon: segment.start.geoCoordinate.lon,
                    },
                    mode: segment.transportMode,
                },
                end: {
                    geoLocation: {
                        lat: segment.end.geoCoordinate.lat,
                        lon: segment.end.geoCoordinate.lon,
                    },
                },
                mode: segment.transportMode,
            }));

            // Add calculated extras and hardCopy to the route
            const transformedRoute = {
                id: route.id,
                segments: route.segments,
                legs: legs,
                stats: {
                    distanceMeters: route.stats.distanceMeters,
                    durationSeconds: route.stats.durationSeconds,
                    bikeConvenience: route.stats.bikeConvenience || null, // Add default values to match v1.0
                    priceAndPayment: {
                        priceInCzk: totalPriceInCzk,
                    },
                },
                _: extra,
                hardCopy: hardCopy,
                start: route.segments[0].start,
                end: route.segments[route.segments.length - 1].end,
            };

            transformedRoute.start.dateTime = transformedRoute.start.plannedAt;
            transformedRoute.end.dateTime = transformedRoute.end.plannedAt;

            return transformedRoute;
        });

        response.data.plannedRoutes = response.data.plannedRoutes.map((transformedRoute) => {
            transformedRoute.start.geoLocation = transformedRoute.start.geoCoordinate;
            transformedRoute.end.geoLocation = transformedRoute.end.geoCoordinate;
            return transformedRoute;
        });

        response.data.routingRequest = response.data.request;
        response.data.routingRequest.origin.geoLocation = response.data.request.origin.geoCoordinate;
        delete response.data.request;

        // Commit OSM usability status
        context.$store.commit("osmUsable", osmUsable);
        // Return the transformed response
        return response;
    },

    "plan-update.post": (response, context) => {
        return response;
    },
};
